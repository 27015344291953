import styles from "../Styles/Sections.module.css";
import OnboardingCard from "./OnboardingCard";
import stylesOnboarding from "../Styles/Onboarding.module.css";
import { useTranslation } from "react-i18next";
import OnboardingCardFree from "./OnboardingCardFree";

function Onboarding() {
  const { t } = useTranslation();

  const freeDescription = {
    includes: [
      t("landing.diagram drawing tool"),
      t("landing.fellings"),
      t("landing.pattern visualization"),
    ],
    notIncludes: [
      t("landing.assisted recipe tool"),
      t("landing.text by size"),
      t("landing.sweater designer"),
      t("landing.sales overview"),
      t("landing.calculation tool"),
      t("landing.expert help"),
      t("landing.knit along"),
      t("landing.model knitting"),
      t("landing.interaction"),
      t("landing.export"),
      t("landing.percentages of yarn sales"),
    ],
  };

  const basicDescription = {
    includes: [
      t("landing.diagram drawing tool"),
      t("landing.fellings"),
      t("landing.pattern visualization"),
      t("landing.assisted recipe tool"),
      t("landing.text by size"),
      t("landing.sweater designer"),
      t("landing.sales overview"),
    ],
    notIncludes: [
      t("landing.calculation tool"),
      t("landing.expert help"),
      t("landing.knit along"),
      t("landing.model knitting"),
      t("landing.interaction"),
      t("landing.export"),
      t("landing.percentages of yarn sales"),
    ],
  };
  const designerDescription = {
    includes: [
      t("landing.diagram drawing tool"),
      t("landing.fellings"),
      t("landing.pattern visualization"),
      t("landing.assisted recipe tool"),
      t("landing.text by size"),
      t("landing.sweater designer"),
      t("landing.sales overview"),
      t("landing.calculation tool"),
      t("landing.one hour of expert help"),
    ],
    notIncludes: [
      t("landing.knit along"),
      t("landing.model knitting"),
      t("landing.interaction"),
      t("landing.export"),
      t("landing.percentages of yarn sales"),
    ],
  };
  const profesionalDescription = {
    includes: [
      t("landing.diagram drawing tool"),
      t("landing.fellings"),
      t("landing.pattern visualization"),
      t("landing.assisted recipe tool"),
      t("landing.text by size"),
      t("landing.sweater designer"),
      t("landing.sales overview"),
      t("landing.calculation tool"),
      t("landing.three hours of expert help"),
      t("landing.knit along"),
      t("landing.model knitting"),
      t("landing.interaction"),
      t("landing.export"),
      t("landing.five percent of yarn sales"),
    ],
    notIncludes: [],
  };

  // const FreeVersion = () => {
  //   return (
  //     <div className={styles.endSection}>
  //       <div className={styles.pictureContainer}>
  //         {/* <img
  //           style={{ borderRadius: "4px", width: "100%" }}
  //           src={ipad_wds}
  //           alt="Illustrasjon av Woolit Design Studio Creative"
  //         /> */}

  //         {/* <div
  //           style={{
  //             position: "absolute",
  //             bottom: "5%",
  //             right: "30%",
  //             left: "18px",
  //           }}
  //         >
  //           <div style={{ color: "white", fontSize: "20px", fontWeight: 500 }}>
  //             Som sett på Norway Chess!
  //           </div>
  //           <div style={{ color: "white" }}>
  //             Carlsen, Nakamura, Gukesh og fler designet sin genser og fikk
  //             deres design strikket!
  //           </div>
  //         </div> */}
  //       </div>

  //       <div className={styles.endSectionContent}>
  //         <div
  //           style={{
  //             fontSize: "16px",
  //             color: "#7E6A5B",
  //             marginBottom: "2%",
  //             fontFamily: "forma-djr-display",
  //           }}
  //         >
  //           Woolit Design Studio Creator
  //         </div>

  //         <div
  //           style={{
  //             fontSize: "30px",
  //             color: "black",
  //             marginBottom: "2%",
  //             fontFamily: "forma-djr-display",
  //           }}
  //         >
  //           Lag mønstre gratis
  //         </div>

  //         <div
  //           style={{
  //             fontSize: "16px",
  //             color: "black",
  //             marginBottom: "2%",
  //             fontFamily: "forma-djr-display",
  //           }}
  //         >
  //           Lag dine egne mønstre med diagramtegneverktøy, gjør fellinger og
  //           visualiser resultatet med Woolit Design Studio Creator.
  //         </div>

  //         <Link
  //           style={{ color: "#33251a", cursor: "pointer", marginTop: "2%" }}
  //           to="https://3d.woolit.no/"
  //         >
  //           <text
  //             style={{
  //               fontSize: "18px",
  //               color: "var(--contrast)",
  //               fontFamily: "forma-djr-display",
  //             }}
  //           >
  //             Gå til{" "}
  //             <text>
  //               Woolit Design Studio!{" "}
  //               <ArrowRightIcon
  //                 style={{ marginBottom: "-3px" }}
  //               ></ArrowRightIcon>
  //             </text>
  //           </text>
  //         </Link>
  //       </div>
  //     </div>
  //   );
  // };

  return (
    <div className={styles.onboardingSection}>
      <div className={stylesOnboarding.onboardingTag}>
        {t("landing.releases 2023")}
      </div>
      <div className={styles.headerTitle}>Woolit Design Studio Pro</div>
      <div className={styles.headerDescription}>
        {t("landing.contact us coffee")}
      </div>

      <div id="onboarding" className={stylesOnboarding.onboardingSection}>
        <OnboardingCardFree
          title={"Woolit Diagram"}
          description={freeDescription}
        ></OnboardingCardFree>
        <OnboardingCard
          title={"Woolit Creator"}
          description={basicDescription}
          price={49}
          flagged={true}
          type={"basic"}
        ></OnboardingCard>
        <OnboardingCard
          title={"Woolit Designer"}
          description={designerDescription}
          price={249}
          flagged={false}
          type={"designer"}
        ></OnboardingCard>
        <OnboardingCard
          title={"Woolit Professional"}
          description={profesionalDescription}
          price={549}
          flagged={false}
          type={"professional"}
        ></OnboardingCard>
      </div>
      {/* <FreeVersion /> */}
    </div>
  );
}

export default Onboarding;
