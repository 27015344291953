import React, { useEffect } from "react";
import HeaderSection from "./LandingPage/Sections/HeaderSection";
import NavigationSection from "./LandingPage/Sections/NavigationSection";
import ProcessSection from "./LandingPage/Sections/ProcessSection";
import CardSection from "./LandingPage/Sections/CardSection";
import WoolitShopSection from "./LandingPage/Sections/WoolitShopSection";
import EndSection from "./LandingPage/Sections/EndSection";
import StatusSection from "./LandingPage/Sections/StatusSection";
import Onboarding from "./LandingPage/Sections/Onboarding";
import ScrollToHashElement from "./CommonComponents/ScrollToHashElement";
import mixpanel from "mixpanel-browser";
import { useLocation } from "react-router";
import {
  useGetFreeSubscriptionMutation,
  useGetPayedSubscriptionMutation,
} from "./store/woolitApi";
import { SubscriptionType } from "./Login/LoginState";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function App() {
  useEffect(() => {
    mixpanel.track("landingPage:view");
  }, []);

  let query = useQuery();
  const [getPayedSubscription] = useGetPayedSubscriptionMutation();
  const [getFreeSubscription] = useGetFreeSubscriptionMutation();

  useEffect(() => {
    const onboardingSubscriptionType = query.get(
      "onboarding"
    ) as SubscriptionType | null;

    if (onboardingSubscriptionType) {
      if (onboardingSubscriptionType === "free") {
        getFreeSubscription();
      } else {
        getPayedSubscription({ subscriptionType: onboardingSubscriptionType });
      }
    }
  }, []);

  return (
    <div style={{ display: "block", justifyContent: "center", width: "100%" }}>
      <ScrollToHashElement />

      <StatusSection />

      <NavigationSection />
      <HeaderSection />
      <ProcessSection />
      <Onboarding />
      <CardSection />

      <WoolitShopSection />
      <EndSection />
    </div>
  );
}

export default App;
