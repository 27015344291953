import mixpanel from "mixpanel-browser";
import styles from "./UpgradeModalContent.module.css";
import { ArrowRightIcon, CheckIcon, Cross1Icon } from "@radix-ui/react-icons";
import { useGetPayedSubscriptionMutation } from "../store/woolitApi";
import { useTranslation } from "react-i18next";

export const UpgradeModalContent: React.FC<{ hide: () => void }> = ({
  hide,
}) => {
  const [getPayedSubscription] = useGetPayedSubscriptionMutation();
  const { t } = useTranslation();

  const getSubscription = async () => {
    mixpanel.track("upgradeModal:subscription", {
      subscriptionType: "basic",
    });

    getPayedSubscription({ subscriptionType: "basic" });
  };

  return (
    <div style={{ display: "flex", height: "412px" }}>
      <div className={styles.image}>
        <h1 className={styles.imageHeader}>
          {t("upgrade.createPatternsKnitalongsAndMore")}
        </h1>
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          className={styles.closeButton}
          onClick={hide}
          data-dismiss="modal"
          aria-label="Close"
        >
          <Cross1Icon />
        </div>

        <div className={styles.textContainer}>
          <h1 className={styles.header}>{t("upgrade.updateAndGainAccess")}</h1>
          <p className={styles.paragraph}>{t("upgrade.getStartedDesigning")}</p>
          <ul className={styles.list}>
            <li className={styles.listElement}>
              <CheckIcon color="green"></CheckIcon>
              <span className={styles.listContent}>
                {t("upgrade.assistedDiagramTool")}
              </span>
            </li>
            <li className={styles.listElement}>
              <CheckIcon color="green"></CheckIcon>
              <span className={styles.listContent}>
                {t("upgrade.textBySize")}
              </span>
            </li>
            <li className={styles.listElement}>
              <CheckIcon color="green"></CheckIcon>
              <span className={styles.listContent}>
                {t("upgrade.sweaterDesigner")}
              </span>
            </li>
            <li className={styles.listElement}>
              <CheckIcon color="green"></CheckIcon>
              <span className={styles.listContent}>
                {t("upgrade.salesOverview")}
              </span>
            </li>
          </ul>
          <div>
            <span className={styles.price}>{t("upgrade.pricePerMonth")}</span>
            <button
              onClick={() => {
                getSubscription();
              }}
              className={styles.ctaButton}
            >
              {t("upgrade.becomeAWoolitCreator")}
            </button>
          </div>
          <a className={styles.seeAllLink} href="/landing#">
            {t("upgrade.seeAllSubscriptions")}
            <ArrowRightIcon
              color={"#D65600"}
              style={{ marginBottom: "-3px" }}
            ></ArrowRightIcon>
          </a>
        </div>
      </div>
    </div>
  );
};
