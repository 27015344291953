import React from "react";
import { ArrowLeftIcon } from "@radix-ui/react-icons";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Button from "../ISO/Components/Button";
import { RootState } from "../store/store";
import { toggleMenuDrawer } from "../store/writerSlice";
import styles from "./MenuDrawer.module.css";
import { OrgDrawer } from "./OrgDrawer";
import { backendApiAddress } from "../backendApi";
import i18n from "../i18n/i18n";
import { isPayedSubscription } from "../Login/LoginState";
import {
  useMeQuery,
  useGetPayedSubscriptionMutation,
} from "../store/woolitApi";

export const MenuDrawer = () => {
  const dispatch = useDispatch();
  const { data: user } = useMeQuery();
  const { menuDrawerOpen } = useSelector((state: RootState) => state.writer);
  const { t } = useTranslation();

  const handleClose = () => {
    dispatch(toggleMenuDrawer());
  };
  const [getPayedSubscription] = useGetPayedSubscriptionMutation();

  return (
    <div
      className={`${styles.drawerContainer} ${
        menuDrawerOpen ? "" : styles.hidden
      }`}
    >
      <div className={styles.drawerBackground} onClick={handleClose} />
      <div className={styles.drawer}>
        <div className={styles.top}>
          <h3>{t("menu")}</h3>
          <div>
            <Button
              style={{
                border: "none",
                fontWeight: i18n.language === "no" ? 600 : 400,
              }}
              onClick={() => {
                i18n.changeLanguage("no");
              }}
            >
              Norsk
            </Button>
            |
            <Button
              style={{
                border: "none",
                fontWeight: i18n.language === "en" ? 600 : 400,
              }}
              onClick={() => {
                i18n.changeLanguage("en");
              }}
            >
              English
            </Button>
            |
            <Button
              style={{
                border: "none",
                fontWeight: i18n.language === "de" ? 600 : 400,
              }}
              onClick={() => {
                i18n.changeLanguage("de");
              }}
            >
              Deutsch
            </Button>
          </div>
          <Button onClick={handleClose}>
            <ArrowLeftIcon />
          </Button>
        </div>
        <OrgDrawer />
        <Link to="/projects">
          <Button onClick={handleClose} className={styles.menuButton}>
            <h4>{t("project", { count: 2 })}</h4>
          </Button>
        </Link>
        {user && isPayedSubscription(user.subscription) && (
          <a href={`${backendApiAddress}/api/wds/payment/portal_session`}>
            <Button onClick={handleClose} className={styles.menuButton}>
              <h4>{t("view_subscription")}</h4>
            </Button>
          </a>
        )}
        {user && user.subscription.isTrial && (
          <>
            <h4 style={{ margin: "10px" }}>
              {t("trial_message", {
                days: user.subscription.trialDaysLeft || 0,
              })}
            </h4>
            <Button
              onClick={() => {
                getPayedSubscription({
                  subscriptionType: user.subscription.subscriptionType,
                });
              }}
              className={styles.menuButton}
            >
              <h4>{t("buy_subscription")}</h4>
            </Button>
          </>
        )}
        {user && user.subscription.subscriptionType === "free" && (
          <Button
            onClick={() => {
              getPayedSubscription({ subscriptionType: "basic" });
            }}
            className={styles.menuButton}
          >
            <h4>{t("buy_full_version")}</h4>
          </Button>
        )}
        {/* <Link to="/salesOverview">
          <Button onClick={handleClose} className={styles.menuButton}>
            <h4>{t("Salgsoversikt", { count: 2 })}</h4>
          </Button>
        </Link>  */}
      </div>
    </div>
  );
};
